/* eslint-disable no-undef */
import Region from "lib/region"
import util from "lib/util"
import  axios  from 'lib/axios'
const { $http } = axios

export default {
  name: "contactDetail",
  data() {
    return {
      detail: {
        xzqName: "",
        name: "",
        phone: "",
        fixedPhone: "",
        address: ""
      },
      showAuto: false,
      autos: [],
      hideTel: false
    }
  },
  methods: {
    validateContact() {
      var detail = this.detail
      var flag = false
      if (!detail.name) {
        this.$toast("姓名不能为空", 1500)
      } else if (this.hideTel && !detail.phone) {
        this.$toast("请填写手机号")
      } else if(!detail.phone && !detail.fixedPhone) {
        this.$toast("手机和座机至少填写一个")
      } else if (detail.phone && !/^1\d{10}$/.test(detail.phone)) {
        this.$toast("请输入正确的手机号码", 1500)
      } else if(detail.fixedPhone && !/^(\(\d{3,4}\)|\d{3,4}(-|\s)?)?\d{6,8}$/.test(detail.fixedPhone)){
        this.$toast("请输入正确座机号码", 1500)
      } else if (!detail.xzqName) {
        this.$toast("请选择行政区", 1500)
      } else if (!detail.address) {
        this.$toast('请填写正确的详细地址', 1500)
      } else {
        flag = true
      }
      return flag
    },
    saveContact() {
      if (!this.validateContact()) return
      if(this.detail.address.length < 4) {
        return this.$toast('详细地址不能少于4个字')
      }
      let detail = this.detail
      let url 
      var data = {
        type: 'MKTSENTCONTACT',
        name: detail.name,
        mobile: detail.phone || "",
        tel: detail.fixedPhone || "",
        province: detail.xzqName.split(',')[0],
        city: detail.xzqName.split(',')[1] || '',
        district: detail.xzqName.split(',')[2] || '',
        addr: detail.address,
        longitude: detail.longitude || '',
        latitude: detail.latitude || ''
      }
      if(detail.id  > 0){
        url = "/apicenter/kdmkt.do?method=exclusiveVisit&act=save"
        data.id = detail.id
      } else {
        url = "/apicenter/kdmkt.do?method=exclusiveVisit&act=addnew"
      }
      this.loading = this.$loading("正在保存...")
      this.$http.post(url, {
        data: data
      }).then(result => {
        if(!this.detail.id) {
          this.detail.id = result.data[0].id
        }
        // 从列表已选择的地址进行编辑以及直接从寄件页面进来的需要选中地址
        if(this.$route.params.source === 'homepage') {
          console.log('detail');
          if(this.$route.params.tag === 'sendman') {
            this.$store.commit('dispatch/SET_SENDMAN', this.detail)
          }
          else if(this.$route.params.tag === 'recman') {
            this.$store.commit('dispatch/SET_RECMAN', this.detail)
          }
          this.$router.replace({
            name: 'dispatch',
            query: {
              from: "homepage"
            }
          })
          this.loadig.hide()
          return 
        }
        if(this.from.query.selectId == this.detail.id || this.$route.params.tag) {
          this.$broadcastChannel.$emit("fillContact", this.detail, this.from.query.tag || this.$route.params.tag)
        }
        this.$router.go(-1)
        this.loadig.hide()
      }).catch(() => {
        this.loading.hide()
      })
    },
    remove() {
      this.$confirm({
        content: "确定删除该地址信息吗？"
      }, () => {
        this.removeContact()
      })
    },
    removeContact: function() {
      this.loading = this.$loading("正在删除...")
      this.$http.post("/apicenter/kdmkt.do?method=deladdressbook", {
        data: {
          id: this.detail.id
        }
      }).then(() => {
        this.loading.hide()
        this.doRemove()
      }).catch(() => {
        this.loadig.hide()
      })
    },
    doRemove: function() {
      this.$toast("地址已删除")
      this.$broadcastChannel.$emit("removeContact", this.detail.id) //通知已选择该地址的页面，移除id，防止编辑异常
      this.$router.go(-1)
    },
    showPicker: function() {
      // if (!this.detail.xzq) {
      //   this.region.value = []
      //   this.region.init();
      // }
      this.region.show((this.detail.xzqName || "").split(","))
    },
    handleAuto: function() {
      this.toggleAuto(true)
      if(!this.detail.address || this.detail.address.indexOf(this.lastaddress) !== 0) {
        this.searchAuto()
      } else {
        this.autos  = []
      }
    },
    searchAuto: function() {
      this.AutoComplete.search(this.detail.address, (status, result) => {
        try {
          if(status == "complete" && result.tips.length) {
            this.autos = result.tips;
          } else {
            this.autos = []
          }
        } catch(e) {
          this.autos = []
        }
      })
    },
    selectAuto:function(item) {
      let detail = this.detail
      detail.address = ((item.name || '').length < 4 ? item.address : '') + item.name
      detail.longitude = (item.location || {}).lng
      detail.latitude = (item.location || {}).lat
      this.lastaddress = detail.address
      this.reconizeContact(item.district + item.address + item.name)
    },
    toggleAuto: function(flag) {
      setTimeout(() => {
        this.showAuto = flag
        setTimeout(() => {
          window.scroll(0,0)
        }, 300)
      }, 0)
    },
    reconizeContact: function(text) {
      if (text || this.detail.reconize) {
        this.$http.post("/order/open/sent.do?method=infosplit", {
          data: {
            content: text || this.detail.reconize
          }
        }).then(result => {
          var data = result.data[0], detail = this.detail
          if(!text) {
            data.name && (detail.name = data.name)
            if(data.mobile && data.mobile.length) {
              if(/^1\d{10}$/.test(data.mobile[0])) {
                detail.phone = data.mobile[0]
                detail.fixedPhone = ""
              } else {
                detail.fixedPhone = data.mobile[0]
                detail.mobile = ""
              }
            }
            data.addr && (detail.address = data.addr)
          }
          data.xzq && data.xzq.fullName && (detail.xzqName = data.xzq.fullName)
          this.$forceUpdate()
        })
      }
    },
  },
  watch: {
    'detail.address': function() {
      if(!this.detail.address || this.detail.address.indexOf(this.lastaddress) !== 0) {
        this.AutoComplete && this.searchAuto()
      }
    },
    'detail.reconize': function() {
      console.log('????')
      this.rcgTimer && clearTimeout(this.rcgTimer)
      this.rcgTimer = setTimeout(() => {
        this.reconizeContact()
      }, 500)
    },
    "detail.xzqName": function(val) {
      var city = val && val.split(",")[1]
      this.AutoComplete && this.AutoComplete.setCity(city)
    }
  },
  mounted() {
    const params = this.$route.params
    if(params.source === 'dispatch' && params.tag === 'sendman') {
      this.hideTel = true
    }
    this.region = new Region({
      others: null,
      level: 2,
      value: (this.detail.xzqName || "").split(","),
      confirm: result => {
        var str = []
        for (let i = 0, len = result.length; i < len; i++) {
          result[i] && (result[i].name) && str.push(result[i].name)
        }
        this.$set(this.detail, 'xzqName', str.join(","))
      },
    })
    util.map.plugin(['AMap.AutoComplete'], () => { //自动填充地址
      this.AutoComplete = new AMap.AutoComplete({
        citylimit: true
      })
    })
  },
  beforeDestroy() {
    this.region.hide()
  },
  async beforeRouteEnter(to, from, next) {
    let source = to.params.source
    let detail = to.params.detail
    console.log(detail)
    // 解密
    if (detail.id && (detail.phone && detail.phone.indexOf('*') > -1) || (detail.fixedPhone && detail.fixedPhone.indexOf('*') > -1)) {
      try {
        const res = await $http.get('/pcweb/addressbook/querybaseaddressbook', {
          data: {
            addrId: detail.id,
            // location: 2,
            addrVersion: 0
          }
        })
        detail.phone = res.data.phone || ''
        detail.fixedPhone = res.data.fixedPhone || ''
      } catch (err) {
        console.log(err)
        detail.phone = ''
        detail.fixedPhone = ''
      }
    }
    next(vm => {
      if(source) {
        vm.source = source
        vm.tag = to.params.tag
        vm.detail = detail || {}
        vm.from = from
        vm.$nextTick(()=> {
          // vm.detail.reconize = ''
        })
      }
    })
  }
}