export default {
  name: "addressList",
  data() {
    return {
      contactList: [],
      type: 3,
      keyword: "",
      selectId: "63545818",
      source: "",
      tag: '',
      offset: 1,
      timer: null,
      aa: '',
      bb: '',
      cc: '',
      isBatch: 0,//批量选择
      batchList: [],
      selectIds: [],
    }
  },
  watch: {
    keyword(val) {
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        this.contactList = []
        this.offset = 1
        this.getContact()
      }, 500)
    }
  },
  computed: {
    list: function() {
      // return this.contactList
      return this.contactList.filter(item => {
        return (this.type == 3 || item.tag == this.type)
      })
      /* let keyword = this.keyword
      return this.contactList.filter(item => {
        return (this.type == 3 || item.tag == this.type) && (item.name.indexOf(keyword) > -1 ||
        item.address.indexOf(keyword) > -1 ||
        (item.phone || '').indexOf(keyword) > -1 ||
        (item.fixedPhone || '').indexOf(keyword) > -1 ||
        (item.xzqName.replace(/,/g, '')).indexOf(keyword) > -1)
      }) */
    }
  },
  methods: {
    scroll(e) {
      /* this.aa = document.getElementById('scroll').scrollHeight
      this.bb = e.target.scrollTop
      this.cc = document.getElementById('scroll').clientHeight */
      if(document.getElementById('scroll').clientHeight + Math.ceil(e.target.scrollTop) >= document.getElementById('scroll').scrollHeight) {
        this.offset += 1
        this.getContact()
      }
    },
    getContact() {
      /* this.$http.get("/apicenter/order.do?method=getaddressbook&tag=3&limit=10&offset=0&searchText=" + this.keyword).then((result) => {
        this.contactList = result.list || []
      }) */
      const params = {
        data: {
          offset: this.offset,
          limit: 30,
          tag: 3,
          searchText: this.keyword || ''
        }
      }
      this.$http.post("/apicenter/xcx.do?method=getSentContact", params).then((res) => {
        const arr = res.data || []
        const newArr = []
        for(let i of arr) {
          newArr.push({
            address: i.addrs[0].addr,
            fixedPhone: i.tel || '',
            id: i.id,
            mixLocation: i.mixLocation || '',
            name: i.name,
            phone: i.mobile || '',
            tag: i.tag || '',
            userId: i.userid,
            xzqName: `${i.addrs[0].province},${i.addrs[0].city},${i.addrs[0].district}`,

            isShowPhone: false,
            temMobile: i.mobile || '',// 中间变量，根据状态存储加密和明文号码
            temTel: i.tel || '',
          })
        }
        this.contactList = this.contactList.concat(newArr)
        if(this.isBatch && this.selectIds.length) {
          this.batchList = this.contactList.filter(v => this.selectIds.includes(v.id))
        }
        if(JSON.stringify(arr) === '[]' && this.offset > 1) {
          this.offset -= 1
        }
      })
    },
    selectContact(item) {
      let cityReg = /香港|澳门|台湾|境外地址/ //recCheck
      if(this.$route.query.tag === 'sendman' && this.$route.query.from === 'dispatch' && !item.phone && item.fixedPhone) {
        return this.$toast({content: '抱歉，寄件人联系方式暂不支持座机，请填写正确手机号码', time: 1000})
      }
      if (this.$route.query.from === 'dispatch' && cityReg.test(item.xzqName.split(',')[1])) {
        return this.$toast({content: '抱歉，目前暂不支持港澳台和国际件。服务正在筹备中，敬请期待！', time: 1000})
      }
      if(this.isBatch){
        const index = this.batchList.findIndex(v => v.id === item.id)
        if(~index) {
          this.batchList.splice(index, 1)
          this.selectIds = this.batchList.map(v => v.id)
        }
        else {
          this.batchList.push(item)
          this.selectIds.push(item.id)
        }
        return
      }
      if(this.source === 'homepage') {
        console.log(this.$route.query.tag);
        if(this.$route.query.tag === 'sendman') {
          this.$store.commit('dispatch/SET_SENDMAN', item)
        }
        else if(this.$route.query.tag === 'recman') {
          this.$store.commit('dispatch/SET_RECMAN', item)
        }
        this.$router.replace({
          name: 'dispatch',
          query: {
            from: "homepage"
          }
        })
        return 
      }
      if(this.source != 'ucenter') {
        this.$broadcastChannel.$emit("fillContact", item, this.$route.query.tag)
        this.$router.go(-1)
      }
    },
    batchSelect() {
      this.$broadcastChannel.$emit("fillContact", this.batchList, this.$route.query.tag)
      this.$router.go(-1)
    },
    showContactDetail(item) {
      this.navigator.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, item),
          source: this.source,
          tag: this.$route.query.tag
        },
        query: {
          from: this.$route.query.from
        }
      })
    },
    async togglePhone(item) {
      if (!item.isShowPhone) {
        if (item.temMobile === item.phone && item.temTel === item.fixedPhone) { // 只有首次两个值才相等
          const data = {
            addrId: item.id,
            location: 1,
            addrVersion: 0
          }
          const res = await this.$http.get('/pcweb/addressbook/querybaseaddressbook', { data })
          item.temMobile = res.data.phone
          item.temTel = res.data.fixedPhone
        }
      }
      [item.temMobile, item.phone] = [item.phone, item.temMobile];
      [item.temTel, item.fixedPhone] = [item.fixedPhone, item.temTel]

      // item.temTel = item.tel
      // item.mobile = res.data.phone
      // item.tel = res.data.fixedPhone
      item.isShowPhone = !item.isShowPhone
    }
  },
  created() {
    console.log(1)
    this.login(() => {
      this.getContact()
    }, () => {
      this.goLogin()
    })
  },
  beforeRouteEnter(to, from, next) {
    let source = to.params.source || to.query.source
    next(vm => {
      vm.source = source || from.params.source || to.query.source
      vm.selectId = to.query.selectId
      vm.isBatch = to.query.isBatch || 0
      if(vm.isBatch) {
        vm.selectIds = to.query.selectIds.split(",") || []
      }
    })
  }

}